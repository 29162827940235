import React from 'react'
import Layout from '../components/Layout'
import { graphql } from 'gatsby'
import { getImage } from 'gatsby-plugin-image'
import { seo } from '../content/transport-miedzynarodowy'
import Seo from '../components/Seo'
import Intro from '../components/Intro'
import TemplateBody from '../components/TemplateBody'
import { useLangContext } from '../context/lang.context'
import { sCenteredSpaced } from '../style'
import Button from '../components/Button'
import { useFormContext } from '../context/form.context'

export default function CountryTemplate({ data }) {
  const {
    name,
    title,
    slug,
    keywords,
    image,
    imagecontent,
    gallery,
    body,
    description,
    createdAt,
    updatedAt,
  } = data.country

  const { lang } = useLangContext()
  const { setFormOpen } = useFormContext()

  const imageData = getImage(image)

  const buttonIntro = {
    text: 'Czytaj więcej',
    action: 'SCROLL',
  }

  const buttonQuote = {
    text: 'Zleć transport',
    action: () => setFormOpen(true),
    glow: true,
  }

  const buttonParent = {
    text: 'Wszystkie kraje',
    link: '/transport-miedzynarodowy/',
    dimmed: true,
  }

  if (typeof window !== `undefined`) {
    const currentUrl = window.location.hash;
    if (currentUrl === "#modal-open") {
      setFormOpen(true);
      window.location.hash = 'modal-shown';
    }
  }

  const krajowyIntroDesc =
    'Realizujemy transport krajowy w całej Polsce. Organizujemy transport w modelu FTL i LTL. Rozwiń z nami swój biznes.'

  const RomaniaIntroDesc =
    `Spedycja międzynarodowa i&nbsp;transport towarów w&nbsp;relacji Polska&nbsp;-&nbsp;Rumunia i&nbsp;Rumunia&nbsp;-&nbsp;Polska`

  return (
    <Layout>
      <Seo
        title={title}
        desc={description}
        imageUrl={image.file.url}
        url={slug}
        keywords={keywords}
        createdAt={createdAt}
        updatedAt={updatedAt}
      />
      <Intro
        h={1}
        title={name}
        desc={slug === 'transport-krajowy' ? krajowyIntroDesc :  (slug === 'transport-polska-rumunia' ? RomaniaIntroDesc : seo.desc[lang])}
        image={imageData}
        button={buttonIntro}
      />
      <TemplateBody
        title={description}
        body={body.childMarkdownRemark.html.replaceAll('h1>', 'h2>')}
        image={imagecontent ? getImage(imagecontent) : imageData}
        imageCredits={image?.description}
        gallery={gallery}
        buttons={[buttonParent, buttonQuote]}
      />
      <div css={sCenteredSpaced}>
        <Button reactive link={buttonBack.link}>
          {buttonBack.text[lang]}
        </Button>
      </div>
    </Layout>
  )
}

const buttonBack = {
  text: {
    pl: 'Powrót do Transport Międzynarodowy',
    en: 'Back to transport by country',
  },
  link: '/transport-miedzynarodowy/',
}

export const query = graphql`
  query ($id: String!) {
    country: contentfulTransportKraj(id: { eq: $id }) {
      id
      slug
      name
      title
      description
      keywords
      createdAt
      updatedAt
      body {
        childMarkdownRemark {
          html
        }
      }
      image {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
          layout: FIXED
        )
        file {
          url
          fileName
        }
      }
      gallery {
        gatsbyImageData(placeholder: DOMINANT_COLOR, formats: [WEBP, AVIF])
        width
        url
        height
      }
      imagecontent {
        description
        gatsbyImageData(
          quality: 100
          placeholder: DOMINANT_COLOR
          formats: [JPG]
          layout: FIXED
        )
      }
    }
  }
`
